<h1>Új eszköz adatai</h1>
<h2>Írd le a jelszót!</h2>
<div>
  @if (data.deviceId) {
    <p>
      Device ID:
      {{ data.deviceId }}
    </p>
    <mat-divider />
  }
  <p>
    MQTT Client ID:
    {{ data.mqttClientId }}
  </p>
  <mat-divider />
  <p>
    MQTT Username:
    {{ data.mqttUserName }}
  </p>
  <mat-divider />
  <p class="font-bold">
    MQTT Password:
    {{ data.mqttPassword }}
  </p>
  <mat-divider />
  <p>
    MQTT URL:
    {{ data.mqttUrl }}
  </p>
  <mat-divider />
  <p>
    MQTT PORT:
    {{ data.mqttPort }}
  </p>
  <mat-divider />
  <p>
    MQTT Topic:
    {{ data.mqttTopic }}
  </p>
</div>
<div class="flex justify-end">
  <button (click)="onClose()" mat-raised-button color="primary">OK</button>
</div>
