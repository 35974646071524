import { inject, Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { DeviceInstallationsModel } from './models/device-installations.model';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { DevicesService } from '../devices/devices.service';
import { UserService } from '../../services/user.service';
import { map } from 'rxjs/operators';
import { NewDeviceInstallationModel } from './models/new-device-installation.model';

@Injectable({
  providedIn: 'root',
})
export class DeviceInstallationsService {
  private readonly devicesService = inject(DevicesService);
  private readonly userService = inject(UserService);

  private readonly baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  getDeviceInstallations(): Observable<DeviceInstallationsModel[]> {
    const url = `${this.baseUrl}devices/v1/device-installations`;
    const deviceInstallations$ = this.http.get<DeviceInstallationsModel[]>(url, { withCredentials: true });
    const devices$ = this.devicesService.getDevices();
    const users$ = this.userService.getUsers();

    return forkJoin([deviceInstallations$, devices$, users$]).pipe(
      map(([deviceInstallations, devices, users]) => {
        return deviceInstallations.map(deviceInstallation => {
          const device = devices.find(device => device.id === deviceInstallation.deviceId);
          const user = users.find(user => user.userId === deviceInstallation.userId);
          return {
            ...deviceInstallation,
            deviceName: device?.deviceName ?? 'Ismeretlen',
            username: user?.firstName || user?.lastName ? `${user.firstName} ${user.lastName}` : 'Ismeretlen',
          };
        });
      }),
    );
  }

  createDeviceInstallations(newDeviceInstallation: NewDeviceInstallationModel): Observable<void> {
    const url = `${this.baseUrl}devices/v1/device-installations`;

    return this.http.post<void>(
      url,
      {
        ...newDeviceInstallation,
      },
      { withCredentials: true },
    );
  }

  deleteDeviceInstallationById(id: string): Observable<void> {
    const url = `${this.baseUrl}devices/v1/device-installations/${id}`;

    return this.http.delete<void>(url, { withCredentials: true });
  }
}
