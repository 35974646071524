import { Component, Inject, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { DatePipe } from '@angular/common';
import { DeviceDetails } from '../../models/device-details.model';

@Component({
  selector: 'dfarm-admin-device-details-dialog',
  standalone: true,
  imports: [MatButton, MatDivider, DatePipe],
  templateUrl: './device-details-dialog.component.html',
  styleUrl: './device-details-dialog.component.scss',
})
export class DeviceDetailsDialogComponent {
  private readonly dialogRef = inject(MatDialogRef<DeviceDetailsDialogComponent>);

  constructor(@Inject(MAT_DIALOG_DATA) public data: DeviceDetails) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
