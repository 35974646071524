<div class="flex items-center justify-between">
  <h1>
    Öntözők -
    {{ title }}
  </h1>
  <button (click)="navigateToNewWateringDevicePage()" mat-raised-button color="primary">Új öntöző felvétele</button>
</div>
@if (wateringData$ | async; as wateringData) {
  <dfarm-admin-watering-data-table [wateringData]="wateringData" />
}
