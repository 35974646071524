<h1>Előfizetés létrehozása</h1>
<form class="form-group" [formGroup]="createSubscriptionForm">
  <div class="custom-inputs">
    <label>Felhasználó</label>
    <select [formControl]="formControls.username">
      @for (user of users; track user) {
        <option [value]="user.username">
          {{ user.username }}
        </option>
      }
    </select>
  </div>
  @if (packages$ | async; as packages) {
    <div class="custom-inputs">
      <label>Csomag</label>
      <select [formControl]="formControls.packageId">
        @for (package of packages; track package) {
          <option [value]="package.id">
            {{ package.name }}
          </option>
        }
      </select>
    </div>
  }
  <div class="custom-inputs">
    <label>
      <input
        type="date"
        class="bg-transparent w-28 text-center text-sm"
        [formControl]="formControls.start"
        [max]="formControls.expiration.value"
        required />
    </label>
    -
    <label>
      <input
        type="date"
        class="bg-transparent w-28 text-center text-sm"
        [formControl]="formControls.expiration"
        [min]="formControls.start.value"
        required />
    </label>
  </div>
  <div class="actions">
    <button class="btn btn-outline-primary" (click)="onCancel()">Mégsem</button>
    <button class="btn btn-primary" (click)="onSubmit()">Előfizetés létrehozása</button>
  </div>
</form>
