import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { UserViewModel } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { LandsService } from '../../services/lands.service';
import { Land } from '../../models/land.model';
import { switchMap, tap } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MapDialogComponent } from '../../shared/map-dialog/map-dialog.component';

@Component({
  selector: 'dfarm-admin-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit {
  subscriptions = new Subscription();
  username = '';
  user!: UserViewModel;

  lands$: Observable<Land[]>;
  displayedColumns: string[] = ['name', 'areaSize', 'location'];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly userService: UserService,
    private readonly landsService: LandsService,
  ) {}

  ngOnInit(): void {
    this.username = this.route.snapshot.paramMap.get('id');
    this.lands$ = this.userService.getUserById(this.username).pipe(
      tap(user => (this.user = user)),
      switchMap(({ userId }) => this.landsService.getLandsByUserId(userId)),
      switchMap(lands => {
        if (!lands.length) {
          return of([]);
        }

        return combineLatest(
          lands.map(land =>
            this.landsService.getFieldLocation(land.coordinates[0]).pipe(
              tap(location => (land.location = location)),
              switchMap(() => of(land)),
            ),
          ),
        );
      }),
    );

    this.lands$.subscribe(lands => console.log(lands));
  }

  deleteUser(username: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: username,
    });

    this.subscriptions.add(
      dialogRef.afterClosed().subscribe(result => {
        if (result !== 'delete') {
          return;
        }
        this.userService.deleteUser(username).subscribe(() => this.router.navigate(['/users']));
      }),
    );
  }

  showLandOnMap(land: Land): void {
    const dialogRef = this.dialog.open(MapDialogComponent, {
      data: land,
    });

    dialogRef.afterClosed().subscribe();
  }

  navigateBackToUsers(): void {
    this.router.navigate(['/users']);
  }
}
