import { Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { UserService } from '../../../../services/user.service';
import { LandsService } from '../../../../services/lands.service';
import { DevicesService } from '../../../devices/devices.service';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { AsyncPipe, NgClass } from '@angular/common';
import { switchMap } from 'rxjs/operators';
import { NewDeviceInstallationModel } from '../../models/new-device-installation.model';

@Component({
  selector: 'dfarm-admin-new-device-installation-dialog',
  standalone: true,
  imports: [MatDialogModule, MatInputModule, MatButtonModule, ReactiveFormsModule, MatSelectModule, AsyncPipe, NgClass],
  templateUrl: './new-device-installation-dialog.component.html',
  styleUrl: './new-device-installation-dialog.component.scss',
})
export class NewDeviceInstallationDialogComponent implements OnInit {
  private readonly dialogRef = inject(MatDialogRef<NewDeviceInstallationDialogComponent>);
  private readonly devicesService = inject(DevicesService);
  private readonly userService = inject(UserService);
  private readonly landsService = inject(LandsService);
  private readonly destroyRef = inject(DestroyRef);

  devices = toSignal(this.devicesService.getDevices());
  users = toSignal(this.userService.getUsers());
  selectedUserId = signal(undefined);
  // TODO: Ideiglenes megoldás, Angular 19-ben írjuk át resource-ra
  lands = toSignal(
    toObservable(this.selectedUserId).pipe(switchMap(userId => this.landsService.getLandsByUserId(userId))),
    {
      initialValue: [],
    },
  );

  formControls: Record<keyof NewDeviceInstallationModel, FormControl> = {
    deviceId: new FormControl('', Validators.required),
    userId: new FormControl('', Validators.required),
    landId: new FormControl('', Validators.required),
    longitude: new FormControl(null, Validators.required),
    latitude: new FormControl(null, Validators.required),
  };
  newDeviceInstallationFormGroup = new FormGroup(this.formControls);

  ngOnInit(): void {
    this.formControls.userId.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(userId => {
      this.selectedUserId.set(userId);
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  saveNewDeviceInstallation(): void {
    if (this.newDeviceInstallationFormGroup.valid) {
      this.dialogRef.close(this.newDeviceInstallationFormGroup.value);
    }
  }
}
