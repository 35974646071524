import { Component, Inject, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { NewDevice } from '../../models/new-device.model';

@Component({
  selector: 'dfarm-admin-new-device-dialog',
  standalone: true,
  imports: [MatDialogModule, MatInputModule, MatButtonModule, ReactiveFormsModule, MatSelectModule],
  templateUrl: './new-device-dialog.component.html',
  styleUrl: './new-device-dialog.component.scss',
})
export class NewDeviceDialogComponent implements OnInit {
  private readonly dialogRef = inject(MatDialogRef<NewDeviceDialogComponent>);

  formControls: Record<keyof NewDevice, FormControl> = {
    deviceName: new FormControl('', Validators.required),
    pcbName: new FormControl(''),
    firmwareVersion: new FormControl(''),
    simId: new FormControl('', Validators.required),
    comment: new FormControl(''),
    craftTime: new FormControl(new Date(), Validators.required),
  };
  newDeviceFormGroup = new FormGroup(this.formControls);

  constructor(@Inject(MAT_DIALOG_DATA) public data: NewDevice | undefined) {}

  ngOnInit(): void {
    if (this.data) {
      this._updateForm();
    }
  }

  saveNewDevice(): void {
    if (this.newDeviceFormGroup.valid) {
      this.formControls.craftTime.patchValue(new Date());
      this.dialogRef.close(this.newDeviceFormGroup.value);
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  private _updateForm(): void {
    this.formControls.deviceName.patchValue(this.data.deviceName);
    this.formControls.pcbName.patchValue(this.data.pcbName);
    this.formControls.firmwareVersion.patchValue(this.data.firmwareVersion);
    this.formControls.simId.patchValue(this.data.simId);
    this.formControls.comment.patchValue(this.data.comment);
  }
}
