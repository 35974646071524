import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { PackageDropdown } from 'src/app/models/packages.model';
import { CreateSubscription } from 'src/app/models/subscriptions.model';
import { UserDropdown } from 'src/app/models/user.model';
import { PackagesService } from 'src/app/services/packages.service';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'dfarm-admin-new-subscription-dialog',
  templateUrl: './new-subscription-dialog.component.html',
  styleUrls: ['./new-subscription-dialog.component.scss'],
})
export class NewSubscriptionDialogComponent implements OnInit {
  maxDate: string;
  formControls: Record<keyof Omit<CreateSubscription, 'user'>, FormControl> = {
    packageId: new FormControl('', Validators.required),
    start: new FormControl('', Validators.required),
    expiration: new FormControl('', Validators.required),
    username: new FormControl('', Validators.required),
  };
  createSubscriptionForm = new FormGroup(this.formControls);

  users: UserDropdown[] = [];
  packages$: Observable<PackageDropdown[]>;

  constructor(
    private readonly subscriptionsService: SubscriptionsService,
    private readonly userService: UserService,
    private readonly packagesService: PackagesService,
    public dialogRef: MatDialogRef<NewSubscriptionDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.maxDate = new Date().toISOString().slice(0, 10);
    this.userService.getUsers().subscribe(users => {
      this.users = users.map(user => ({
        ...user,
      }));
    });
    this.packages$ = this.packagesService.getPackages();
  }

  onSubmit(): void {
    if (!this.createSubscriptionForm.valid) {
      return;
    }

    this.subscriptionsService
      .createSubscription(this.createSubscriptionForm.value as CreateSubscription, this.users)
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
