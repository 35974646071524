<div class="mat-elevation-z8">
  <table [dataSource]="wateringData" mat-table>
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th *matHeaderCellDef mat-header-cell>Név</th>
      <td *matCellDef="let row" mat-cell>
        {{ row.name }}
      </td>
    </ng-container>

    <!-- Date of Manufacture Column -->
    <ng-container matColumnDef="dateOfManufacture">
      <th *matHeaderCellDef mat-header-cell>Gyártási év</th>
      <td *matCellDef="let row" mat-cell>
        {{ row.dateOfManufacture | date: 'yyyy.MM.dd. HH:mm' }}
      </td>
    </ng-container>

    <!-- Brand Column -->
    <ng-container matColumnDef="brand">
      <th *matHeaderCellDef mat-header-cell>Márka</th>
      <td *matCellDef="let row" mat-cell>
        {{ row.brand }}
      </td>
    </ng-container>

    <!-- Date of Installation Column -->
    <ng-container matColumnDef="dateOfInstallation">
      <th *matHeaderCellDef mat-header-cell>Telepítési év</th>
      <td *matCellDef="let row" mat-cell>
        {{ row.dateOfInstallation | date: 'yyyy.MM.dd. HH:mm' }}
      </td>
    </ng-container>

    <!-- Capacity Column -->
    <ng-container matColumnDef="capacity">
      <th *matHeaderCellDef mat-header-cell>Kapacitás</th>
      <td *matCellDef="let row" mat-cell>
        {{ row.capacity }}
      </td>
    </ng-container>

    <!-- User Column -->
    <ng-container matColumnDef="user">
      <th *matHeaderCellDef mat-header-cell>Felhasználó</th>
      <td *matCellDef="let row" mat-cell>
        {{ row.user }}
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns" class="cursor-pointer" mat-row></tr>

    <!-- Row shown when there is no matching data. -->
    <tr *matNoDataRow class="mat-row">
      <td class="mat-cell" colspan="4">A felhasználónak nincs öntözője</td>
    </tr>
  </table>
</div>
