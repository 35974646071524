<h2 mat-dialog-title>
  {{ data ? 'Eszköz módosítása' : 'Új eszköz felvétel' }}
</h2>
<mat-dialog-content class="!flex flex-col gap-2 w-full" [formGroup]="newDeviceFormGroup">
  <mat-form-field class="w-full">
    <mat-label>Device Name</mat-label>
    <input [formControl]="formControls.deviceName" matInput />
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>PCB Name</mat-label>
    <input [formControl]="formControls.pcbName" matInput />
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>Firmware Version</mat-label>
    <input [formControl]="formControls.firmwareVersion" matInput />
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>Sim ID</mat-label>
    <input [formControl]="formControls.simId" matInput />
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>Komment</mat-label>
    <input [formControl]="formControls.comment" matInput />
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="w-full flex justify-end gap-2">
    <button (click)="onClose()" mat-button color="warn">Mégse</button>
    <button (click)="saveNewDevice()" mat-raised-button cdkFocusInitial color="primary">Mentés</button>
  </div>
</mat-dialog-actions>
