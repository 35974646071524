<div class="flex justify-between mb-2">
  <h1 class="!m-0">Eszköz hozzárendelések</h1>
  <button (click)="showAddNewDeviceInstallationDialog()" mat-raised-button color="primary">Eszköz hozzárendelés</button>
</div>
<table class="mat-elevation-z8" [dataSource]="dataSource" mat-table>
  <!-- Device Name Column -->
  <ng-container matColumnDef="deviceName">
    <th *matHeaderCellDef mat-header-cell>Device Name</th>
    <td *matCellDef="let element" mat-cell>
      {{ element.deviceName }}
    </td>
  </ng-container>

  <!-- Username Column -->
  <ng-container matColumnDef="username">
    <th *matHeaderCellDef mat-header-cell>Felhasználó neve</th>
    <td *matCellDef="let element" mat-cell>
      {{ element.username }}
    </td>
  </ng-container>

  <!-- Land ID Column -->
  <ng-container matColumnDef="landId">
    <th *matHeaderCellDef mat-header-cell>Föld ID</th>
    <td *matCellDef="let element" mat-cell>
      {{ element.landId }}
    </td>
  </ng-container>

  <!-- Coordinates Column -->
  <ng-container matColumnDef="coordinates">
    <th *matHeaderCellDef mat-header-cell>Koordináták</th>
    <td *matCellDef="let element" mat-cell>
      <div>
        Longitude:
        {{ element.longitude }}
      </div>
      <div>
        Latitude:
        {{ element.latitude }}
      </div>
    </td>
  </ng-container>

  <!-- IsActive Column -->
  <ng-container matColumnDef="isActive">
    <th *matHeaderCellDef mat-header-cell>Aktív</th>
    <td *matCellDef="let element" mat-cell>
      {{ element.isActive ? 'Aktív' : 'Nem aktív' }}
    </td>
  </ng-container>

  <!-- Creation Time Column -->
  <ng-container matColumnDef="creationTime">
    <th *matHeaderCellDef mat-header-cell>Létrehozás</th>
    <td *matCellDef="let element" mat-cell>
      {{ element.creationTime | date }}
    </td>
  </ng-container>

  <!-- Actions Column -->
  <ng-container matColumnDef="actions">
    <th *matHeaderCellDef mat-header-cell></th>
    <td *matCellDef="let element" mat-cell>
      <button [matMenuTriggerFor]="menu" mat-icon-button>
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button (click)="deleteDeviceInstallation(element.id, element.deviceName)" mat-menu-item>
          <mat-icon>delete</mat-icon>
          Törlés
        </button>
      </mat-menu>
    </td>
  </ng-container>

  <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
  <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
</table>
