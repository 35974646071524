import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { UserViewModel } from '../../models/user.model';
import { UserService } from '../../services/user.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InviteUserDialogComponent } from '../../shared/invite-user-dialog/invite-user-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'dfarm-admin-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  displayedColumns: string[] = ['name', 'username', 'role', 'actions'];
  sortedUsers: UserViewModel[] = [];
  userTable: MatTableDataSource<UserViewModel> | undefined;
  path = 'users/';
  subscriptions = new Subscription();
  open = 'Kinyit';
  users!: UserViewModel[];

  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  ngAfterViewInit(): void {
    if (!this.userTable) {
      return;
    }
    this.userTable.paginator = this.paginator;
    this.userTable.sort = this.sort;
  }

  inviteUser(): void {
    const dialogRef = this.dialog.open(InviteUserDialogComponent);

    this.subscriptions.add(
      dialogRef.afterClosed().subscribe(newUserEmail => {
        if (!newUserEmail) {
          return;
        }
        this.subscriptions.add(
          this.userService.sendRegistrationEmail(newUserEmail).subscribe(() => {
            this.snackBar.open(`Email sent to ${newUserEmail}`, 'Close', {
              duration: 5000,
            });
            this.loadData();
          }),
        );
      }),
    );
  }

  navigateToUserDetails(username: string): void {
    this.router.navigate(['/users', username]);
  }

  deleteUser(username: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: username,
    });

    this.subscriptions.add(
      dialogRef.afterClosed().subscribe(result => {
        if (result !== 'delete') {
          return;
        }
        this.subscriptions.add(this.userService.deleteUser(username).subscribe(() => this.loadData()));
      }),
    );
  }

  // handlePageEvent(pageEvent: PageEvent): void {
  //   this.pageSize = pageEvent.pageSize;
  //   this.pageIndex = pageEvent.pageIndex;
  // }

  sortData(sort: Sort): void {
    const data: UserViewModel[] = this.users.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedUsers = data;
      return;
    }

    this.sortedUsers = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.compare(a.firstName, b.firstName, isAsc);
        case 'username':
          return this.compare(a.username, b.username, isAsc);
        // case 'fat':
        //   return this.compare(a.fat, b.fat, isAsc);
        // case 'carbs':
        //   return this.compare(a.carbs, b.carbs, isAsc);
        // case 'protein':
        //   return this.compare(a.protein, b.protein, isAsc);
        default:
          return 0;
      }
    });

    this.userTable = new MatTableDataSource(this.sortedUsers);
  }

  private loadData(): void {
    this.userService
      .getUsers()
      .pipe(take(1))
      .subscribe(users => {
        this.users = users;
        this.sortedUsers = users;
        this.userTable = new MatTableDataSource(this.sortedUsers);
      });
  }

  private compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
