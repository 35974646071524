<h1>Eszköz adatai</h1>
<div>
  <p>
    Device ID:
    {{ data.id }}
  </p>
  <mat-divider />
  <p>
    Device Name:
    {{ data.deviceName }}
  </p>
  <mat-divider />
  <p>
    PCB Name:
    {{ data.pcbName }}
  </p>
  <mat-divider />
  <p>
    Firmware Version:
    {{ data.firmwareVersion }}
  </p>
  <mat-divider />
  <p>
    SIM ID:
    {{ data.simId }}
  </p>
  <mat-divider />
  <p>
    Komment:
    {{ data.comment }}
  </p>
  <mat-divider />
  <p>
    Craft Time:
    {{ data.craftTime | date }}
  </p>
  <mat-divider />
  <p>
    Creation Time:
    {{ data.creationTime | date }}
  </p>
  <mat-divider />
  <p>
    Utoljára módosítva:
    {{ data.lastModificationTime | date }}
  </p>
  <mat-divider />
</div>
<div class="flex justify-end">
  <button (click)="onClose()" mat-raised-button color="primary">OK</button>
</div>
