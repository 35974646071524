<h1>Új öntöző létrehozása</h1>
<form class="flex flex-col" [formGroup]="newWateringDeviceForm">
  <mat-form-field>
    <mat-label>Név</mat-label>
    <input [formControl]="formControls.name" matInput placeholder="Név" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Márka</mat-label>
    <input [formControl]="formControls.brand" matInput placeholder="Márka" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Kapacitás</mat-label>
    <input [formControl]="formControls.capacity" matInput placeholder="Kapacitás" />
  </mat-form-field>
  @if (users$ | async; as users) {
    <mat-form-field>
      <mat-label>Felhasználó</mat-label>
      <mat-select [formControl]="formControls.userId">
        @for (user of users; track user) {
          <mat-option [value]="user.userId">
            {{ user.firstName }}
            {{ user.lastName }}
            -
            {{ user.username }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }
  <mat-form-field>
    <mat-label>Gyártási év</mat-label>
    <input [formControl]="formControls.dateOfManufacture" [matDatepicker]="dateOfManufacture" matInput />
    <mat-hint>MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle [for]="dateOfManufacture" matIconSuffix></mat-datepicker-toggle>
    <mat-datepicker #dateOfManufacture></mat-datepicker>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Teleptítési év</mat-label>
    <input [formControl]="formControls.dateOfInstallation" [matDatepicker]="dateOfInstallation" matInput />
    <mat-hint>MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle [for]="dateOfInstallation" matIconSuffix></mat-datepicker-toggle>
    <mat-datepicker #dateOfInstallation></mat-datepicker>
  </mat-form-field>
</form>
<div class="flex justify-end">
  <button (click)="save()" mat-raised-button color="primary">Mentés</button>
</div>
