<h2 mat-dialog-title>Új eszköz hozzárendelés</h2>
<mat-dialog-content class="!flex flex-col gap-2 w-full" [formGroup]="newDeviceInstallationFormGroup">
  <mat-form-field class="w-full">
    <mat-label>Longitude</mat-label>
    <input type="number" [formControl]="formControls.longitude" matInput />
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>Latitude</mat-label>
    <input
      type="number"
      [ngClass]="{
        'text-red-500': formControls.latitude.invalid && formControls.latitude.touched,
      }"
      [formControl]="formControls.latitude"
      matInput />
  </mat-form-field>
  @if (devices()) {
    <mat-form-field class="w-full">
      <mat-label>Eszközök</mat-label>
      <mat-select [formControl]="formControls.deviceId">
        @for (device of devices(); track device) {
          <mat-option [value]="device.id">
            {{ device.deviceName }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }
  @if (users()) {
    <mat-form-field class="w-full">
      <mat-label>Felhasználók</mat-label>
      <mat-select [formControl]="formControls.userId">
        @for (user of users(); track user) {
          <mat-option [value]="user.userId">
            {{ user.username }}
            -
            {{ user.firstName }}
            {{ user.lastName }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }
  @if (lands().length > 0) {
    <mat-form-field class="w-full">
      <mat-label>Föld</mat-label>
      <mat-select [formControl]="formControls.landId">
        @for (land of lands(); track land) {
          <mat-option [value]="land.id">
            {{ land.name }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }
</mat-dialog-content>
<mat-dialog-actions>
  <div class="w-full flex justify-end gap-2">
    <button (click)="onClose()" mat-button color="warn">Mégse</button>
    <button (click)="saveNewDeviceInstallation()" mat-raised-button cdkFocusInitial color="primary">Mentés</button>
  </div>
</mat-dialog-actions>
