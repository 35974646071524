import { Component, Input } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { WateringData } from '../../models/watering.model';

@Component({
  selector: 'dfarm-admin-watering-data-table',
  standalone: true,
  imports: [CommonModule, MatTableModule],
  templateUrl: './watering-data-table.component.html',
  styleUrl: './watering-data-table.component.scss',
})
export class WateringDataTableComponent {
  @Input()
  wateringData: WateringData[] = [];
  displayedColumns: string[] = ['name', 'dateOfManufacture', 'brand', 'dateOfInstallation', 'capacity', 'user'];
}
