<div class="container">
  <div class="flex justify-between items-center">
    <h1 class="!mb-0">Felhasználók</h1>
    <button (click)="inviteUser()" mat-raised-button color="primary">Felhasználó meghívása</button>
  </div>

  <mat-divider class="my-4"></mat-divider>
  <!-- Data Table Section -->
  @if (userTable) {
    <div class="mat-elevation-z8">
      <table [dataSource]="userTable" (matSortChange)="sortData($event)" mat-table matSort>
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Név</th>
          <td *matCellDef="let row" mat-cell>
            <a class="flex items-center gap-2" [href]="path + row.username"> {{ row.lastName }} {{ row.firstName }} </a>
          </td>
        </ng-container>
        <!-- Username (email) Column -->
        <ng-container matColumnDef="username">
          <th *matHeaderCellDef mat-header-cell mat-sort-header>Felhasználónév</th>
          <td *matCellDef="let row" mat-cell>
            <a [href]="path + row.username">
              {{ row.username }}
            </a>
          </td>
        </ng-container>
        <!-- Role Column -->
        <ng-container matColumnDef="role">
          <th *matHeaderCellDef mat-header-cell>Szerepkör</th>
          <td *matCellDef="let row" mat-cell>
            <a [href]="path + row.username">
              {{ row.role }}
            </a>
          </td>
        </ng-container>
        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let row" mat-cell>
            <div class="flex items-center justify-end gap-2">
              <button (click)="$event.stopPropagation(); deleteUser(row.username)" mat-button color="warn">
                Törlés
              </button>
              <button
                (click)="$event.stopPropagation(); navigateToUserDetails(row.username)"
                mat-raised-button
                color="primary">
                Részletek
              </button>
            </div>
          </td>
        </ng-container>
        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr
          *matRowDef="let row; columns: displayedColumns"
          class="cursor-pointer"
          (click)="navigateToUserDetails(row.username)"
          mat-row></tr>
        <!-- Row shown when there is no matching data. -->
        <tr *matNoDataRow class="mat-row">
          <!-- <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td> -->
        </tr>
      </table>
      <!--     <mat-paginator -->
      <!--       (page)="handlePageEvent($event)" -->
      <!--       [length]="userTable.data.length ?? 0" -->
      <!--       [pageIndex]="pageIndex" -->
      <!--       [pageSizeOptions]="[5, 10, 25, 100]" -->
      <!--       [pageSize]="pageSize" -->
      <!--       [showFirstLastButtons]="true" -->
      <!--       aria-label="Select page of user"></mat-paginator> -->
    </div>
  }
</div>
