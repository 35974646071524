<h1>Előfizetés szerkesztése</h1>
<form class="form-group" [formGroup]="editSubscriptionForm">
  <div class="custom-inputs">
    Felhasználó:
    {{ this.data.user.username }}
  </div>
  <div class="custom-inputs">
    Csomag:
    {{ this.data.packageName }}
  </div>
  <div class="custom-inputs">
    <label>
      <input
        type="date"
        class="bg-transparent w-28 text-center text-sm"
        [formControl]="formControls.start"
        [max]="formControls.expiration.value"
        required />
    </label>
    -
    <label>
      <input
        type="date"
        class="bg-transparent w-28 text-center text-sm"
        [formControl]="formControls.expiration"
        [min]="formControls.start.value"
        required />
    </label>
  </div>
  <div class="actions">
    <button class="btn btn-outline-primary" (click)="onCancel()">Mégsem</button>
    <button class="btn btn-primary" (click)="onSubmit()">Előfizetés szerkesztése</button>
  </div>
</form>
