import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserViewModel } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  sendRegistrationEmail(email: string): Observable<any> {
    const url = this.baseUrl + 'admin/accounts/users/invite';

    return this.http.post<any>(
      url,
      { email },
      {
        withCredentials: true,
      },
    );
  }

  getUsers(): Observable<UserViewModel[]> {
    const url = this.baseUrl + 'admin/accounts/users';

    return this.http.get<UserViewModel[]>(url, {
      withCredentials: true,
    });
  }

  getUserById(username: string): Observable<UserViewModel> {
    const url = `${this.baseUrl}admin/accounts/users/${username}`;

    return this.http.get<UserViewModel>(url, {
      withCredentials: true,
    });
  }

  deleteUser(username: string): Observable<void> {
    const url = `${this.baseUrl}admin/accounts/users/${username}`;

    return this.http.delete<void>(url, {
      withCredentials: true,
    });
  }
}
