import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { EditSubscriptionDialogComponent } from './edit-subscription-dialog/edit-subscription-dialog.component';
import { NewSubscriptionDialogComponent } from './new-subscription-dialog/new-subscription-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [EditSubscriptionDialogComponent, NewSubscriptionDialogComponent],
  imports: [CommonModule, MatButtonModule, FormsModule, ReactiveFormsModule],
  exports: [EditSubscriptionDialogComponent],
})
export class SharedModule {}
