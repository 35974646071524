import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditSubscription, RoleSubscription } from 'src/app/models/subscriptions.model';
import { UserDropdown } from 'src/app/models/user.model';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'dfarm-admin-edit-subscription-dialog',
  templateUrl: './edit-subscription-dialog.component.html',
  styleUrls: ['./edit-subscription-dialog.component.scss'],
})
export class EditSubscriptionDialogComponent implements OnInit {
  maxDate: string;
  formControls: Record<keyof Omit<EditSubscription, 'user'>, FormControl> = {
    id: new FormControl(''),
    start: new FormControl('', Validators.required),
    expiration: new FormControl('', Validators.required),
  };
  editSubscriptionForm = new FormGroup(this.formControls);

  users: UserDropdown[] = [];

  constructor(
    private readonly subscriptionsService: SubscriptionsService,
    private readonly userService: UserService,
    public dialogRef: MatDialogRef<EditSubscriptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: RoleSubscription,
  ) {}

  ngOnInit(): void {
    this.maxDate = new Date().toISOString().slice(0, 10);
    this.userService.getUsers().subscribe(users => {
      this.users = users.map(user => ({
        ...user,
      }));
    });

    if (this.data) {
      this.editSubscriptionForm.patchValue(this.data);
      this.formControls.start.patchValue(new Date(this.data.start).toISOString().slice(0, 10));
      this.formControls.expiration.patchValue(new Date(this.data.expiration).toISOString().slice(0, 10));
    }
  }

  onSubmit(): void {
    if (!this.editSubscriptionForm.valid) {
      return;
    }

    this.subscriptionsService.editSubscription(this.editSubscriptionForm.value as EditSubscription).subscribe(() => {
      this.dialogRef.close();
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
