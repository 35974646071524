<div>
  <ul>
    <li>
      <a [routerLink]="['users']" routerLinkActive="active">Felhasználók</a>
    </li>
    <li>
      <a [routerLink]="['/lands']" routerLinkActive="active">Földterületek</a>
    </li>
    <li>
      <a [routerLink]="['/subscriptions']" routerLinkActive="active">Előfizetések</a>
    </li>
    <li>
      <a [routerLink]="['/devices']" routerLinkActive="active">Eszközök</a>
    </li>
    <li>
      <a [routerLink]="['/device-installations']" routerLinkActive="active">Eszköz hozzárendelés</a>
    </li>
    <li>
      Öntőzők
      <ul>
        <li>
          <a [routerLink]="['/watering/linear']" routerLinkActive="active">Lineár</a>
        </li>
        <li>
          <a [routerLink]="['/watering/center-pivot']" routerLinkActive="active">Center Pivot</a>
        </li>
        <li>
          <a [routerLink]="['/watering/cylinder-watering']" routerLinkActive="active">Dobos öntöző</a>
        </li>
        <li>
          <a [routerLink]="['/watering/underground-dripper']" routerLinkActive="active">Földalatti csepegtető</a>
        </li>
      </ul>
    </li>
  </ul>
  <ul>
    <li><a (click)="logout()">Kijelentkezés</a></li>
  </ul>
</div>
