import { Component, inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { DeviceInstallationsModel } from './models/device-installations.model';
import { DeviceInstallationsService } from './device-installations.service';
import { DatePipe } from '@angular/common';
import { NewDeviceInstallationDialogComponent } from './components/new-device-installation-dialog/new-device-installation-dialog.component';
import { NewDeviceInstallationModel } from './models/new-device-installation.model';

@Component({
  selector: 'dfarm-admin-device-installations',
  standalone: true,
  imports: [MatTableModule, MatButtonModule, MatMenuModule, MatIcon, DatePipe],
  templateUrl: './device-installations.component.html',
  styleUrl: './device-installations.component.scss',
})
export class DeviceInstallationsComponent implements OnInit {
  readonly dialog = inject(MatDialog);
  private readonly deviceInstallationsService = inject(DeviceInstallationsService);

  displayedColumns: string[] = [
    'deviceName',
    'username',
    'landId',
    'coordinates',
    'isActive',
    'creationTime',
    'actions',
  ];
  dataSource: DeviceInstallationsModel[] = [];

  ngOnInit(): void {
    this._loadData();
  }

  showAddNewDeviceInstallationDialog(): void {
    const dialogRef = this.dialog.open(NewDeviceInstallationDialogComponent, {
      minWidth: '400px',
      maxWidth: '600px',
    });

    dialogRef.afterClosed().subscribe((newDeviceInstallation: NewDeviceInstallationModel) => {
      if (!newDeviceInstallation) {
        return;
      }

      this._saveNewDeviceInstallation(newDeviceInstallation);
    });
  }

  deleteDeviceInstallation(deviceInstallationId: string, deviceInstallationName: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: deviceInstallationName,
      minWidth: '400px',
      maxWidth: '600px',
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      if (result !== 'delete') {
        return;
      }

      this.deviceInstallationsService
        .deleteDeviceInstallationById(deviceInstallationId)
        .subscribe(() => this._loadData());
    });
  }

  private _loadData(): void {
    this.deviceInstallationsService
      .getDeviceInstallations()
      .subscribe(deviceInstallations => (this.dataSource = deviceInstallations));
  }

  private _saveNewDeviceInstallation(newDeviceInstallation: NewDeviceInstallationModel): void {
    this.deviceInstallationsService.createDeviceInstallations(newDeviceInstallation).subscribe(() => this._loadData());
  }
}
