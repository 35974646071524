import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WateringData } from '../models/watering.model';
import { FormControl, ɵFormGroupValue, ɵTypedOrUntyped } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class WateringDeviceService {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  getLinearWateringDevices(): Observable<WateringData[]> {
    // const url = this.baseUrl + 'admin/linear-irrigators?userId=' + userId;
    const url = this.baseUrl + 'linear-irrigators';

    return this.http.get<WateringData[]>(url, {
      withCredentials: true,
    });
  }

  getLinearWateringDevicesByUser(userId?: string): Observable<WateringData[]> {
    // const url = this.baseUrl + 'admin/linear-irrigators?userId=' + userId;
    const url = `${this.baseUrl}admin/linear-irrigators?userId=${userId ?? ''}`;

    return this.http.get<WateringData[]>(url, {
      withCredentials: true,
    });
  }

  addNewWateringDevice(
    wateringDevice: ɵTypedOrUntyped<
      Record<'name' | 'dateOfManufacture' | 'brand' | 'dateOfInstallation' | 'capacity' | 'user', FormControl<any>>,
      ɵFormGroupValue<
        Record<'name' | 'dateOfManufacture' | 'brand' | 'dateOfInstallation' | 'capacity' | 'user', FormControl<any>>
      >,
      any
    >,
  ): Observable<string> {
    const url = this.baseUrl + 'admin/linear-irrigators';

    return this.http.post<string>(url, wateringDevice, {
      withCredentials: true,
    });
  }

  editWateringDevice(wateringDevice: WateringData): Observable<string> {
    const url = this.baseUrl + 'admin/linear-irrigators';

    return this.http.put<string>(url, wateringDevice, {
      withCredentials: true,
    });
  }

  deleteWateringDevice(wateringDeviceId: string): Observable<string> {
    const url = this.baseUrl + 'admin/linear-irrigators/' + wateringDeviceId;

    return this.http.delete<string>(url, {
      withCredentials: true,
    });
  }
}
