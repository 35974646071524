import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DeviceList } from './models/devices.model';
import { DeviceConfirmationDialogModel } from './models/device-confirmation-dialog.model';
import { DeviceDetails } from './models/device-details.model';
import { NewDevice } from './models/new-device.model';

@Injectable({
  providedIn: 'root',
})
export class DevicesService {
  private readonly baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  getDevices(): Observable<DeviceList[]> {
    const url = `${this.baseUrl}devices/v1/devices`;

    return this.http.get<DeviceList[]>(url, { withCredentials: true });
  }

  getDeviceById(id: string): Observable<DeviceDetails> {
    const url = `${this.baseUrl}devices/v1/devices/${id}`;

    return this.http.get<DeviceDetails>(url, { withCredentials: true });
  }

  addNewDevice(newDevice: NewDevice): Observable<DeviceConfirmationDialogModel> {
    const url = `${this.baseUrl}devices/v1/devices`;

    return this.http.post<DeviceConfirmationDialogModel>(
      url,
      {
        ...newDevice,
      },
      { withCredentials: true },
    );
  }

  editDevice(id: string, newDevice: NewDevice): Observable<void> {
    const url = `${this.baseUrl}devices/v1/devices/${id}`;

    return this.http.put<void>(
      url,
      {
        ...newDevice,
      },
      { withCredentials: true },
    );
  }

  regeneratePasswordForDevice(id: string): Observable<DeviceConfirmationDialogModel> {
    const url = `${this.baseUrl}devices/v1/devices/${id}/mqtt-client/regenerate-password`;

    return this.http.put<DeviceConfirmationDialogModel>(url, undefined, { withCredentials: true });
  }

  deleteDeviceById(id: string): Observable<void> {
    const url = `${this.baseUrl}devices/v1/devices/${id}`;

    return this.http.delete<void>(url, { withCredentials: true });
  }
}
