<div class="p-8">
  <h1>Felhasználó email címe</h1>
  <mat-form-field class="w-full">
    <mat-label>Email</mat-label>
    <input type="email" [formControl]="emailFormControl" matInput />
  </mat-form-field>
  <div class="flex justify-between gap-2 items-center">
    <button class="flex-1" (click)="dialogRef.close()" mat-button color="primary">Mégse</button>
    <button class="flex-1" (click)="save()" mat-raised-button color="primary">Mentés</button>
  </div>
</div>
