<div class="p-8">
  <h1>
    Biztosan törölni akarod a(z)
    <strong>
      {{ data }}
    </strong>
    ?
  </h1>
  <div class="flex justify-end gap-4 items-center">
    <button (click)="dialogRef.close()" mat-button color="primary">Mégsem</button>
    <button (click)="dialogRef.close('delete')" mat-raised-button color="warn" cdkFocusInitial>Törlés</button>
  </div>
</div>
