import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'dfarm-admin-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;

  returnUrl: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  selectChange(event): void {
    if (event.target.value === 'hu') {
      console.log('HU', event);
    }
    // this.props.dispatch(languageActions.hungarian);
    if (event.target.value === 'en') {
      console.log('EN', event);
    }
    // this.props.dispatch(languageActions.english);
    if (event.target.value === 'sk') {
      console.log('SK', event);
    }
    // this.props.dispatch(languageActions.slovakian);
    if (event.target.value === 'de') {
      console.log('DE', event);
    }
    // this.props.dispatch(languageActions.german);
  }

  login(): void {
    this.authService.loginUser(this.username, this.password).subscribe(res => {
      this.router.navigate([this.returnUrl]);
    });
  }
}
