import { Component, inject, OnInit } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { NewDeviceDialogComponent } from './components/new-device-dialog/new-device-dialog.component';
import { DeviceList } from './models/devices.model';
import { DevicesService } from './devices.service';
import { DeviceConfirmationDialogModel } from './models/device-confirmation-dialog.model';
import { DeviceConfirmationDialogComponent } from './components/device-confirmation-dialog/device-confirmation-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { DeviceDetailsDialogComponent } from './components/device-details-dialog/device-details-dialog.component';
import { NewDevice } from './models/new-device.model';

@Component({
  selector: 'dfarm-admin-devices',
  standalone: true,
  imports: [MatTableModule, MatButtonModule, MatMenuModule, MatIcon],
  templateUrl: './devices.component.html',
  styleUrl: './devices.component.scss',
})
export class DevicesComponent implements OnInit {
  readonly dialog = inject(MatDialog);
  private readonly devicesService = inject(DevicesService);
  displayedColumns: string[] = ['deviceName', 'simId', 'firmwareVersion', 'comment', 'actions'];
  dataSource: DeviceList[] = [];

  ngOnInit(): void {
    this._loadData();
  }

  showDetailsDialog(deviceId: string): void {
    this.devicesService.getDeviceById(deviceId).subscribe(device => {
      const dialogRef = this.dialog.open(DeviceDetailsDialogComponent, {
        data: device,
        minWidth: '400px',
        maxWidth: '600px',
      });

      dialogRef.afterClosed().subscribe();
    });
  }

  showAddNewDeviceDialog(): void {
    const dialogRef = this.dialog.open(NewDeviceDialogComponent, {
      minWidth: '400px',
      maxWidth: '600px',
    });

    dialogRef.afterClosed().subscribe((newDevice: NewDevice) => {
      if (!newDevice) {
        return;
      }

      this._saveNewDevice(newDevice);
    });
  }

  showEditDeviceDialog(deviceId: string): void {
    this.devicesService.getDeviceById(deviceId).subscribe(device => {
      const dialogRef = this.dialog.open(NewDeviceDialogComponent, {
        data: device,
        minWidth: '400px',
        maxWidth: '600px',
      });

      dialogRef.afterClosed().subscribe((newDevice: NewDevice) => {
        if (!newDevice) {
          return;
        }

        this._editDevice(deviceId, newDevice);
      });
    });
  }

  regeneratePassword(deviceId: string): void {
    this.devicesService.regeneratePasswordForDevice(deviceId).subscribe(deviceConfirmation => {
      if (!deviceConfirmation) {
        return;
      }

      this._showDeviceConfirmationDialog(deviceConfirmation);
    });
  }

  deleteDevice(deviceId: string, deviceName: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: deviceName,
      minWidth: '400px',
      maxWidth: '600px',
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      if (result !== 'delete') {
        return;
      }

      this.devicesService.deleteDeviceById(deviceId).subscribe(() => this._loadData());
    });
  }

  private _saveNewDevice(newDevice: NewDevice): void {
    this.devicesService.addNewDevice(newDevice).subscribe(deviceConfirmation => {
      if (!deviceConfirmation) {
        return;
      }

      this._loadData();
      this._showDeviceConfirmationDialog(deviceConfirmation);
    });
  }

  private _editDevice(deviceId: string, newDevice: NewDevice): void {
    this.devicesService.editDevice(deviceId, newDevice).subscribe(() => {
      this._loadData();
    });
  }

  private _showDeviceConfirmationDialog(deviceConfirmation: DeviceConfirmationDialogModel): void {
    const dialogRef = this.dialog.open(DeviceConfirmationDialogComponent, {
      data: deviceConfirmation,
      minWidth: '400px',
      maxWidth: '600px',
    });

    dialogRef.afterClosed().subscribe();
  }

  private _loadData(): void {
    this.devicesService.getDevices().subscribe(devices => (this.dataSource = devices));
  }
}
