<div class="flex justify-between mb-2">
  <h1 class="!m-0">Eszközök</h1>
  <button (click)="showAddNewDeviceDialog()" mat-raised-button color="primary">Eszköz felvétel</button>
</div>
<table class="mat-elevation-z8" [dataSource]="dataSource" mat-table>
  <!-- Device Name Column -->
  <ng-container matColumnDef="deviceName">
    <th *matHeaderCellDef mat-header-cell>Device Name</th>
    <td *matCellDef="let element" mat-cell>
      {{ element.deviceName }}
    </td>
  </ng-container>

  <!-- Sim ID Column -->
  <ng-container matColumnDef="simId">
    <th *matHeaderCellDef mat-header-cell>Sim ID</th>
    <td *matCellDef="let element" mat-cell>
      {{ element.simId }}
    </td>
  </ng-container>

  <!-- Firmware Version Column -->
  <ng-container matColumnDef="firmwareVersion">
    <th *matHeaderCellDef mat-header-cell>Firmware Version</th>
    <td *matCellDef="let element" mat-cell>
      {{ element.firmwareVersion }}
    </td>
  </ng-container>

  <!-- Comment Column -->
  <ng-container matColumnDef="comment">
    <th *matHeaderCellDef mat-header-cell>Comment</th>
    <td *matCellDef="let element" mat-cell>
      {{ element.comment }}
    </td>
  </ng-container>

  <!-- Actions Column -->
  <ng-container matColumnDef="actions">
    <th *matHeaderCellDef mat-header-cell></th>
    <td *matCellDef="let element" mat-cell>
      <button [matMenuTriggerFor]="menu" mat-icon-button>
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button (click)="showDetailsDialog(element.id)" mat-menu-item>
          <mat-icon>list_alt</mat-icon>
          Részletek
        </button>
        <button (click)="showEditDeviceDialog(element.id)" mat-menu-item>
          <mat-icon>edit</mat-icon>
          Módosítás
        </button>
        <button (click)="regeneratePassword(element.id)" mat-menu-item>
          <mat-icon>lock</mat-icon>
          Jelszó újra generálás
        </button>
        <button (click)="deleteDevice(element.id, element.deviceName)" mat-menu-item>
          <mat-icon>delete</mat-icon>
          Törlés
        </button>
      </mat-menu>
    </td>
  </ng-container>

  <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
  <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
</table>
