<div class="actions">
  <button class="btn btn-outline-primary" (click)="createSubscription()">Előfizetés létrehozás</button>
</div>
@if (subscriptions$ | async; as subscriptions) {
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">&nbsp;</th>
        <th scope="col">Felhasználó neve</th>
        <th scope="col">Csomag neve</th>
        <th scope="col">Előfizetés kezdete</th>
        <th class="fit" scope="col">Előfizetés vége</th>
        <th class="fit" scope="col">Utoljára módosítva</th>
        <th class="fit" scope="col">Módosító neve</th>
        <th scope="col">&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      @for (subscription of subscriptions; track subscription; let i = $index) {
        <tr>
          <td scope="row">
            <strong>
              {{ i + 1 }}
            </strong>
          </td>
          <td>
            {{ subscription.user.username }}
          </td>
          <td>
            {{ subscription.packageName }}
          </td>
          <td>
            {{ subscription.start | date: 'yyyy.MM.dd. HH:mm' }}
          </td>
          <td>
            {{ subscription.expiration | date: 'yyyy.MM.dd. HH:mm' }}
          </td>
          <td>
            {{ subscription.lastModification | date: 'yyyy.MM.dd. HH:mm' }}
          </td>
          <td>
            {{ subscription.modifiedBy }}
          </td>
          <td>
            @if (!subscription.deleted) {
              <div style="display: flex; gap: 8px">
                <button class="btn btn-primary" (click)="editSubscription(subscription)">Módosítás</button>
                <button class="btn btn-danger" (click)="deleteSubscription(subscription)">Törlés</button>
              </div>
            } @else {
              <div>Törölve</div>
            }
          </td>
        </tr>
      }
    </tbody>
  </table>
}
