<dfarm-admin-navbar></dfarm-admin-navbar>

<mat-drawer-container class="drawer-container">
  <mat-drawer mode="side" opened>
    <dfarm-admin-menu></dfarm-admin-menu>
  </mat-drawer>
  <mat-drawer-content class="p-2">
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
